section#home {
    height: auto;

    > div {
        padding: 0.5rem;
        min-height: 200px;

        &.flex-grow-3 {
            flex-basis: 100%;
        }
        &.flex-grow-2 {
            flex-basis: 100%;
        }
        &.flex-grow-5 {
            flex-basis: 100%;
        }

        .home-item {
            display: block;
            position: relative;
            width: 100%; 
            height: 100%;
            overflow: hidden;
            border: none;
            outline: none;

            .background {
                position: absolute; 
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center center;
                -webkit-transform: scale(1);
                transform: scale(1);
                /*-webkit-filter: blur(0);
                filter: blur(0);*/
                -webkit-filter: sepia(0%);
	            filter: sepia(0%);
                transition: all 0.5s 0.1s;
            }

            div.ct-title {
                position: absolute; 
                bottom: 0;
                left: 0;
                width: 100%;
                height: auto;
                background-color: rgba(0, 0, 0, 0.6);
                color: #fff;

                h2 {
                    font-family: 'Roboto Condensed', sans-serif;
                    font-size: 26px;
                    margin: 0; 
                    padding: 0;
                    font-weight: 700;
                    color: #dea95e;
                }
                p {
                    font-family: 'Roboto Condensed', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0;
                    padding: 0;
                }

                .active-title {
                    padding: 0.5rem;
                    transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                    -webkit-transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                    transform: translate(0, 0);
                    opacity: 1;
                }
                .hover-title {
                    position: absolute; 
                    top: 0;
                    left: 0;
                    padding: 0.5rem;
                    transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                    -webkit-transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                    transform: translate(0, 20px);
                    opacity: 0;
                }
            }

            &:hover{
                 .background {
                    transition: all 0.4s 0.1s;
                    /*-webkit-filter: blur(3px);
                    filter: blur(3px);*/
                    -webkit-filter: sepia(100%);
                    filter: sepia(100%);
                    -webkit-transform: scale(1.05);
                    transform: scale(1.05);
                }
                div.ct-title {
                    .active-title {
                        transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                        -webkit-transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                        transform: translate(0, 20px);
                        opacity: 0;
                    }
                    .hover-title {
                        transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                        -webkit-transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                        transform: translate(0, 0px);
                        opacity: 1;
                    }
                }
            }
        }
    }

    .carousel {
        width: 100%;
        height: 100%;

        .carousel-inner {
            width: 100%;
            height: 100%;
            
            .carousel-item {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 100%;

                div.img {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center center;
                }
            }
        }
    }

    #home-flag {
        display: none;
        position: fixed; 
        top: 0; 
        right: 24px;
        width: 36px;
        height: 100vh;
        background-color: red;
        background-image: url(../Images/home-flag.svg);
        background-position: top right;
    }

    .actu-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .carousel-indicators {
            top: 0 !important;
            justify-content: flex-end;
            margin-right: 5%;
            height: 40px;
        }
        
        h2 {
            font-size: 14px !important;
            text-align: right;
        }
    }
    .actu-title {
        position: absolute;
        width: 113px;
        top: 15px; left: -27px;
        text-transform: uppercase;
        background-color: #6f1212;
        color: #fff;
        text-align: center;
        line-height: 30px;
        transform: rotate(-45deg);
    }
}

@include media-breakpoint-up(sm) { 
    section#home {
        > div {
            &.flex-grow-3 {
                flex-basis: 50%;
            }
            &.flex-grow-2 {
                flex-basis: 50%;
            }
            &.flex-grow-5 {
                flex-basis: 100%;
            }
        }
    }
}
@include media-breakpoint-up(md) { 
    section#home {
        min-height: calc(100vh - 105px - 56px);
        
        > div { 
            &.flex-grow-3 {
                flex-basis: 37.5%;
            }
            &.flex-grow-2 {
                flex-basis: 25%;
            }
            &.flex-grow-5 {
                flex-basis: 62.5%;
            }
        }
    } 

    #home-flag {
        z-index: 9999;
        display: block !important;
    }
}
@include media-breakpoint-up(lg) {  }
@include media-breakpoint-up(xl) {  }