section#contact {
    .bloc-left, .bloc-right, .bloc-center {
        padding: 2rem;
    }
    .bloc-left, .bloc-right {
        background-color: #6f1212;
    }
    .bloc-center {
        background-color: #fff;
        color: #000;

        label {
            font-weight: 300;
        }
        input[type=text] {
            font-size: 1em;
            background-color: #eee;
            border-radius: 10px;
            border: none;
        }
        input[type=submit] {
            background-color: #dea95e;
            color: #fff;
            padding: 0.5rem 3rem;
        }
    }

    .day {
        position: relative;
        z-index: 0;
        margin-bottom: 20px;
    
        span.label {
            font-size: 18px;
            font-weight: 300;
            color: #fff;
            width: calc(100% - 80px);
    
            span {
                padding-right: 20px;
            }
        }
        .horaire {
            color: #dea95e;
            font-size: 18px;
            font-weight: 400;
            width: 180px;
            text-align: right;
            background-color: #6f1212;
        }

        &:after {
            position: absolute;
            bottom: 35%;
            left: 0;
            right: 0;
            content: '';
            width: 100%;
            height: 1px;
            border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
            z-index: -1;
        }
    } 
}
