nav.navbar {
    font-size: 18px;

    .navbar-collapse {
        
    }
    .navbar-nav {
        font-size: 17px; 
        justify-content: space-between;
        display: flex;
        align-items: center;

        .nav-item {
            display: flex;
            align-items: center;
        }    
        a.nav-link, 
        a.nav-link:hover,
        a.nav-link:active,
        a.nav-link:visited,
        a.nav-link:focus {
            position: relative;
            color: #fff;
            text-transform: uppercase;
        }
        a:after {
            content: ' ';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background-color: none;
            background-image: url(../Images/flag.svg);
            transition: all 0.6s ease;
            opacity: 0;
        }
        a.nav-link:hover:after, a.nav-link.active:after {
            opacity: 1;
            transition: all 0.6s ease;
        }
        .dropdown-toggle::after {
            border: none !important;
        }
    }
}