section#actualites {
    height: auto;

    .actuality-illustration { max-width: 50%; }

    .item {
        display: block;
        position: relative;
        outline: none;
        border: none;
        width: 100%; 
        height: 300px;
        overflow: hidden;

        .background {
            position: absolute; 
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            -webkit-transform: scale(1);
            transform: scale(1);
            /*-webkit-filter: blur(0);
            filter: blur(0);*/
            -webkit-filter: sepia(0%);
            filter: sepia(0%);
            transition: all 0.5s 0.1s;
        }

        div.ct-title {
            position: absolute; 
            display: flex;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            background-color: rgba(0, 0, 0, 0.6);
            color: #fff;
            justify-content: center;
            align-items: center;

            h2 {
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 26px;
                margin: 0; 
                padding: 0;
                font-weight: 700;
                color: #dea95e;
            }
            p {
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 16px;
                font-weight: 400;
                margin: 0;
                padding: 0;
            }

            .active-title {
                padding: 0.5rem;
                transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                -webkit-transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                transform: translate(0, 0);
                opacity: 1;
            }
            .hover-title {
                position: absolute; 
                display: flex;
                justify-content: center;
                vertical-align: middle;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                padding: 0.5rem;
                transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                -webkit-transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                transform: translate(0, 20px);
                opacity: 0;
            }
        }

        &:hover{
                .background {
                transition: all 0.4s 0.1s;
                /*-webkit-filter: blur(3px);
                filter: blur(3px);*/
                -webkit-filter: sepia(100%);
                filter: sepia(100%);
                -webkit-transform: scale(1.05);
                transform: scale(1.05);
            }
            div.ct-title {
                .active-title {
                    transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                    -webkit-transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                    transform: translate(0, 20px);
                    opacity: 0;
                }
                .hover-title {
                    transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                    -webkit-transition: transform 0.3s 0.2s, opacity 0.5s 0.2s;
                    transform: translate(0, 0px);
                    opacity: 1;
                }
            }
        }
    }
}