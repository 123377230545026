div.menu {
    .nav.nav-pills {
        .nav-item {
            background-color: #6f1212;
            padding: 0.5rem 0.5rem;
            text-align: center;
            width: 100%;

            &:first-of-type {
                padding-left: 0.5rem;
            }
            &:last-of-type {
                padding-right: 0.5rem;
            }

            a.nav-link {
                color: #ffffff;
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
                border-radius: 0 !important;

                &:visited {
                    color: #ffffff;
                }
                &:hover, &:active, &:focus {
                    color: #dea95e;
                    border: none !important;
                    outline: none; 
                }
                &.active {
                    position: relative;
                    color: #dea95e;
                    background-color: #6f1212;
    
                }
    
                &.active:after {
                    content: ' ';
                    position: absolute; 
                    bottom: -1rem;
                    left: calc(50% - 18px);
                    display : none;
                    height : 0;
                    width : 0;
                    border-right : 18px solid transparent;
                    border-bottom : 16px solid #dea95e;
                    border-left : 18px solid transparent;
                }
            }
        }
    }

    .tab-content {
        padding: 2rem;
        background-color: #fff;

        .item {
            margin-bottom: 20px;
        
            &.exergue {
                background-color: rgba(222, 169, 94, 0.2);
                padding: 2rem;
                border: 1px solid rgba(255, 255, 255, 0.5);

                .price {
                    //color: #fff;
                    background-color: transparent;
                }
                .text {
                    &:after {
                        //border-bottom-color: #ffffff;
                    }
                }
            }

            h3 {
                color: #000000;
                font-family: 'Alex Brush', cursive;
                font-size: 30px;
                font-weight: normal;
                width: calc(100% - 80px);
                margin-bottom: 0;
    
                span {
                    padding-right: 20px;

                    small {
                        margin-left: 0.5rem;
                    }
                }
            }
            span.item-title {
                display: inline-block;
                font-family: 'Alex Brush', cursive;
                font-size: 28px;
                font-weight: normal;
                width: calc(100% - 80px);
    
                span {
                    padding-right: 20px;
                }
            }
            .price {
                color: #dea95e;
                font-size: 18px;
                font-weight: 400;
                width: 105px;
                text-align: right;
                background-color: #fff;
            }
            .text {
                position: relative;
                z-index: 0;
    
                &:after {
                    position: absolute;
                    bottom: 20%;
                    left: 0;
                    right: 0;
                    content: '';
                    width: 100%;
                    height: 1px;
                    border-bottom: 1px dashed #dea95e;
                    z-index: -1;
                }
            }
            .desc {
                font-family: 'Signika', sans-serif;
                font-size: 15px;
                font-weight: normal;
                color: #6f1212;
            }
            [data-icon=pepper-hot]:first-of-type {
                margin-left: 10px;
            }
        }
    }   

    [data-icon=pepper-hot] {
        color: $primaryColor;
    }
}

@include media-breakpoint-up(lg) { 
    div.menu {
        .nav.nav-pills {
            .nav-item {
                padding: 1rem 0.5rem;
    
                &:first-of-type {
                    padding-left: 2rem;
                }
                &:last-of-type {
                    padding-right: 2rem;
                }

                width: auto;

                a.nav-link {
                    &.active:after {
                        display: inline-block;
                    }
                }
            }
        }

        .tab-content {
            .item {
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}